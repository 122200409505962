import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    background: #fff;
    width: 100%;
    height: 100%;
    
    .title-bg{
        position: absolute;
        left:0;
        top: -100px;
        width:504px;
    }

    &>.h{
        display: flex;
        height: 100%;
        align-items: center;
    }

    .home-content{
        width: 100%;
        position: relative;
        margin-top: -200px;
    }
    .left-box{
        margin-top: 89px;
        color: #0D1A26;
        position: relative;
        z-index: 10;
    }

    @media screen and (min-height: 1200px) {
        .left-box {
            margin-top: -100px;
        }
        .home-content{
            margin-top: -200px;
        }
        .right-box{
            top: -115px !important;
        }
    }

    .left-box h2{
        font-size: 56px;
        line-height: 76px;
        position: relative;
    }
    .left-box p{
        margin-top: 39px !important;
        width: 500px;
        font-size: 16px;
        letter-spacing: 0;
        text-align: justify;
        line-height: 36px;
    }
    .left-box button{
        margin-top: 70px;
        margin-right: 20px;
        border-radius: 27px;
        width: 178px;
        height: 48px;
        border: 1px solid #1890FF;
    }
    .right-box{
        position: absolute;
        left: 277px;
        top: -50px;
        z-index: 9;
    }
    .r-login{
        background: #fff !important;
        color: #1890FF !important;
    }
    .gk{
        position: absolute;
        width: 100%;
        bottom: -30px;
        left: 0;
    }
    .gk img{
        width: 100%;
        background-size:100% 100%;
    }
`;

export default IndexStyleComponent;