import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Preview from './pages/preview/index';
import * as serviceWorker from './serviceWorker';

import {Button} from 'antd';
import 'antd/dist/antd.css';
import 'antd/dist/antd.js';

// import '../assets/stylesheets/reset.css';
// import 'stylesheets/reset.css';

// 引入路由
// import { BrowserRouter as Router } from 'react-router-dom';
import {
    HashRouter as Router , // 或者是HashRouter、MemoryRouter
} from 'react-router-dom';

ReactDOM.render(
    <Router>
        {/* <App /> */}
        <Preview />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
