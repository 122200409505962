import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';
import IndexStyleComponent from './indexStyleComponent';

class Footer extends Component{
    render(){
        return (
            <Fragment>
                <IndexStyleComponent className="fiexheight">
                    <div className="h">
                        <div className="cp-box">
                            <ul>
                                <li>
                                    <i className="call"></i>
                                    <span>客服热线：</span>
                                    <h2>400-9696-592</h2>
                                </li>
                                {/* <li>
                                    <i className="time"></i>
                                    <span>服务时间：</span>
                                    <span>08:00——17:00</span>
                                </li> */}
                                <li>
                                    <i className="address"></i>
                                    <span>公司地址：</span>
                                    <span>杭州市萧山区民和路481号南区联合中心B座10楼</span>
                                </li>
                                <li>
                                    <i className="www"></i>
                                    <span>公司网址：</span>
                                    <span>www.nkcsiot.com</span>
                                </li>
                                <li className="copyright">
                                    <span>版权所有&nbsp;&copy;</span>
                                    <span>2019杭州南开承盛物联科技有限公司</span>
                                    <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" className="cy-link" target="_blank">
                                        <span>浙ICP备16028625号-2</span>
                                    </a>
                                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002093" target="_blank">
                                        <i className="police"></i>
                                        <span>浙公网安备 33010902002093号</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="footer-bg">footer背景图</div>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Footer;